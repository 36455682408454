//	Inset lists

.mm-listview .mm-inset
{
	list-style: inside disc;
	padding: 0 $mm_listitemPadding ( $mm_listitemPadding * 1.5 ) ( $mm_listitemIndent * 2 );
	margin: 0;

	> li
	{
		padding: ( $mm_listitemPadding / 2 ) 0;
	}
}