@import "../../css/_imp/import";


/*
	jQuery.mmenu position extension CSS
*/

.mm-menu.mm-top,
.mm-menu.mm-bottom
{
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

.mm-menu.mm-right
{
	left: auto;
	right: 0;
}

.mm-menu.mm-bottom
{
	top: auto;
	bottom: 0;
}

@include mm_sizing_right;



/*
	jQuery.mmenu z-position extension CSS
*/

// reset defaults
html.mm-front
{
	.mm-slideout
	{
		transform: none !important;
		z-index: 0;
	}
	#mm-blocker
	{
		z-index: 1;
	}
	.mm-menu
	{
		z-index: 2;
	}
}

// animations
.mm-menu
{
	&.mm-front,
	&.mm-next
	{
		transition: transform $mm_transitionDuration $mm_transitionFunction;
		transform: translate3d( -100%, 0, 0 );

		&.mm-right
		{
			transform: translate3d( 100%, 0, 0 );
		}
	}
	&.mm-top
	{
		transform: translate3d( 0, -100%, 0 );
	}
	&.mm-bottom
	{
		transform: translate3d( 0, 100%, 0 );
	}
}
html.mm-opening .mm-menu
{
	&.mm-front,
	&.mm-next
	{
		transform: translate3d( 0, 0, 0 );
	}
}

@include mm_sizing_zposition;