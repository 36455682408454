$vendor-prefixes-enabled: true;
// Default transition length
$trans-length: 0.3s;
$trans-length-navbar: 0.5s;

@import 'colors';
@import 'borders';
@import 'breakpoints';
@import 'folders';
@import 'sizes';
@import 'typography';
@import 'z-index-map';
