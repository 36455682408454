$opacity-dkr: 0.85;
$opacity-dk: 0.7;
$opacity-md: 0.5;
$opacity-lt: 0.2;
$opacity-ltr: 0.15;
$transparent: rgba(255,255,255,0);

// Semitransparent white
$trBlack-dkr: rgba(0, 0, 0, $opacity-dkr);
$trBlack-dk: rgba(0, 0, 0, $opacity-dk);
$trBlack: rgba(0, 0, 0, $opacity-md);
$trBlack-lt: rgba(0, 0, 0, $opacity-lt);
$trBlack-ltr: rgba(0, 0, 0, $opacity-ltr);

// Semitransparent black
$trWhite-dkr: rgba(255, 255, 255, $opacity-dkr);
$trWhite-dk: rgba(255, 255, 255, $opacity-dk);
$trWhite: rgba(255, 255, 255, $opacity-md);
$trWhite-lt: rgba(255, 255, 255, $opacity-lt);
$trWhite-ltr: rgba(255, 255, 255, $opacity-ltr);

// Brand colors
$blue: rgb(0, 79, 159);
$black: rgb(0, 0, 0);
$black-dk: rgb(20, 20, 20);
$fc-black: rgb(31, 31, 31);
$gray: rgb(153, 153, 153);
$gray-dk: rgb(128, 128, 128);
$gray-xdk: #1F1F1F;

// Background colors
$bg-gray: rgb(210, 210, 210);

// Layout backrgound colors
$bg-navbar: $black;

// Font colors
$font-color: $trWhite;
$font-black: #424242;

// Border colors
$border-color: #ccc;
$border-color-dk: #808080;
$border-color-dkr: #565656;

// More colors

