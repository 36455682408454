body {
    @media ($min-lg) {
        .site-header {
            &.has-submenu {
                padding: 0 0 80px 0;
            }
        }
    }
}

.site-header {
    position: relative;
}

.header-navbar {
    position: fixed;
    z-index: $z-navbar;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 28px 30px;
    color: $gray;
    text-transform: uppercase;
    @include transition(padding $trans-length);
    a {
        font-family: inherit;
        color: inherit;
        @include on-state {
            text-decoration: none;
            color: #fff;
        }
    }
    li.active a {
        text-decoration: none;
        color: #fff;
    }
    @include before-absolute {
        background-color: $bg-navbar;
        width: 100%;
        top: -100%;
        left: 0;
        height: 100%;
        opacity: 0;
        z-index: 0;
        -webkit-transition: top $trans-length-navbar, opacity 0.2s;
        -moz-transition: top $trans-length-navbar, opacity 0.2s;
        transition: top $trans-length-navbar, opacity 0.2s;
    }
    > *:not(.header-nav-menu-toggle-js) {
        position: relative;
    }
    > * {
        z-index: 1;
    }
    &.navbar-bg {
        &:before {
            top: 0;
            opacity: 1;
        }
    }
    .vp-scrolled & {
        @include padding-v(15px);
        &:before {
            top: 0;
            opacity: 1;
        }
    }
    @media ($min-lg) {
        @include padding-h(30px);
        @include padding-v(35px);
        .vp-scrolled & {
            @include padding-v(20px);
        }
    }
    @media ($min-xl) {
        @include padding-h(60px);
        @include padding-v(35px);
    }
    @media ($max-sm) {
        padding: 28px 15px;
    }
}

.header-logo-block {
    float: left;
    width: 148px;
    z-index: 2;
    @media ($min-sm) {
        width: 148px;
    }
    @media ($min-lg) {
        width: 170px;
        position: absolute !important;
    }
}


.language-block {
    //width: auto;
    position: absolute !important;
    top: 32px;
    right: 30px;
    z-index: 0;
    opacity: 1;
    transition: opacity .2s linear;

    ul{
        :nth-child(2) {
            margin-left: 10px;
        }
        li {
            list-style-type: none;
            display: inline;
        }
    }

    .active {
        color: white;
    }

    @media (min-width:590px) {
        top: 39px;
        right: 45px;
    }

    @media (max-width: 1200px) {
        top: 32px;
        right: 90px;
    }
    @media ($min-lg) {
        right: 165px;
    }
    @media (max-width:330px) {
        top: 32px;
        right: 77px;

        ul{
            :nth-child(2) {
                margin-left: 7px;
            }
        }
    }

    @media (max-width:310px) {
        top: 75px;
        right: 15px;
    }
}

.vp-scrolled {
    .language-block {
        opacity: 0;
    }
}

.language-block--hidden {
    display: none;
}

.lang-active {
    color: white;
}

.header-logo {
    display: block;
}

.header-nav-menu {
    ul {
        @include reset-list;
    }

    a,span {
        font-size: $fs-h7;
        letter-spacing: .05em;
        @include proximaNova;
    }
    &.mm-menu {
        a,span {
            font-size: $fs-h7;
            &:hover {
                color: white;
            }
        }

        ul {
            max-width: 440px;
        }
    }
    &:not(.opened):not(.mm-menu):not(.active) {
        visibility: hidden;
    }
    &.desktop-nav-menu {

        @media ($max-lg) {
            text-align: left;
            //width: auto;
            margin: auto;
            position: relative;

            li > a {
                &hover {
                    color: white;
                }
            }
        }

        @media ($min-lg) {
            text-align: center;

            ul {
                vertical-align: top;
                @include inline-list;
                padding-top: 5px;
                display: inline-block;
                width: auto;
                padding-left: 0;

                li {
                    display: inline-block;
                    padding: 0 30px;
                    position: relative;
                }
            }

            .mm-divider,
            .mm-divider ~ li {
                display: none;
            }

            ul ul {
                display: none;
                position: absolute;
                background: $black;
                text-align: left;
                font-size: $fs-base;
                padding: 10px 20px;
                margin-left: -20px;
                white-space: nowrap;
            }

            div > ul {
                li {
                    top: -50px;
                    opacity: 0;
                    -webkit-transition: top $trans-length, opacity 0.2s;
                    -moz-transition: top $trans-length, opacity 0.2s;
                    transition: top $trans-length, opacity 0.2s;
                    &:nth-child(1) {
                        -webkit-transition-delay: .1s;
                        -moz-transition-delay: .1s;
                        transition-delay: .1s;
                    }
                    &:nth-child(2) {
                        -webkit-transition-delay: .2s;
                        -moz-transition-delay: .2s;
                        transition-delay: .2s;
                    }
                    &:nth-child(3) {
                        -webkit-transition-delay: .3s;
                        -moz-transition-delay: .3s;
                        transition-delay: .3s;
                    }
                    &:nth-child(4) {
                        -webkit-transition-delay: .4s;
                        -moz-transition-delay: .4s;
                        transition-delay: .4s;
                    }
                    &:nth-child(5) {
                        -webkit-transition-delay: .5s;
                        -moz-transition-delay: .5s;
                        transition-delay: .5s;
                    }
                    &:nth-child(6) {
                        -webkit-transition-delay: .6s;
                        -moz-transition-delay: .6s;
                        transition-delay: .6s;
                    }
                    &:nth-child(7) {
                        -webkit-transition-delay: .7s;
                        -moz-transition-delay: .7s;
                        transition-delay: .7s;
                    }
                }
            }
            &.opened {
                ul > li {
                    top: 0;
                    opacity: 1;
                }
            }
        }
    }
}

.header-nav-menu-toggle-js {
    position: absolute;
    z-index: 2;
    right: 30px;
    display: block;
    width: 30px;
    height: 20px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    &:hover {
        .line {
            background-color: white;
        }
    }
    &:not(.opened):hover {
        .toggler-label {
            opacity: 1;
        }
    }
    .toggler-label {
        display: none;
        position: absolute;
        top: 0;
        line-height: 22px;
        right: 43px;
        font-size: 1rem;
        color: $border-color;
        opacity: 0;
        @include transition(opacity $trans-length);
        @media ($min-lg) {
            display: block;
        }
    }
    .line {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: $border-color;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        transform-origin: left center;
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 16px;
        }
    }

    .mm-opened & .line:nth-child(1),
    &.opened .line:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -1px;
        left: 4px;
        width: 100%;
    }

    .mm-opened & .line:nth-child(2),
    &.opened .line:nth-child(2) {
        width: 0;
        opacity: 0;
    }

    .mm-opened & .line:nth-child(3),
    &.opened .line:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 20px;
        left: 4px;
        width: 100%;
    }

    @media ($max-sm) {
        top: 34px;
    }

    @media ($min-sm) {
        width: 30px;
        height: 20px;
        top: 34px;

        .line {
            height: 4px;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2) {
                top: 8px;
            }
            &:nth-child(3) {
                top: 16px;
            }
        }
        .mm-opened & .line:nth-child(1),
        &.opened .line:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -1px;
            left: 4px;
            width: 100%;
        }

        .mm-opened & .line:nth-child(3),
        &.opened .line:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 20px;
            left: 4px;
            width: 100%;
        }
    }
    @media ($min-lg) {
        right: 49px;
        top: 40px;
    }
}

.vp-scrolled .header-nav-menu-toggle-js {
    @media ($max-sm) {
        top: 16px;
    }

    @media ($min-sm) {
        top: 16px;
    }

    @media ($min-lg) {
        top: 24px;
    }
}

.header-carousel--slide-image {
    display: block;
    overflow: hidden;
    position: relative;
    div {
        min-height: 100%;
        min-width: 100%;
        position: relative;
        //display: inline-block;
        max-width: 100%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        -webkit-background-repeat: no-repeat;
        -moz-background-repeat: no-repeat;
        -o-background-repeat: no-repeat;
        background-repeat: no-repeat;
        @include before-absolute {
            @include square(100%);
            background: transparentize($black, 0.5);
        }

        @media ($max-sm) {
            background-position: center center;
            background-attachment: inherit;
        }
    }
}

.header-carousel--slide-image-startpage {

    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    text-align: center;

    div {
        min-height: 100%;
        min-width: 100%;
        position: relative;
        display: inline-block;
        max-width: 100%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center center;
        -webkit-background-repeat: no-repeat;
        -moz-background-repeat: no-repeat;
        -o-background-repeat: no-repeat;
        background-repeat: no-repeat;
    }
}

.page--start .header-carousel--slide-image {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.page--start .header-carousel--slide-image-startpage {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.page--start .slick-current {
    -webkit-animation: imageAnimation 4.5s linear 1 0s;
    -moz-animation: imageAnimation 4.5s linear 1 0s;
    -ms-animation: imageAnimation 4.5s linear 1 0s;
    animation: imageAnimation 4.5s ease-out 1 0s;
}

.page--start .header-carousel--slide .page-title {
    opacity: 0.5;
}

.header-section {
    position: relative;
}

.arrow {
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    margin-left: -14px;
    z-index: 6;
    font-size: 40px;

    .bounce {
        -webkit-animation-name: bounce;
        animation-name: bounce;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
    }

    .animated {
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
    }
    60% {
        -webkit-transform: translateY(-10px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}