@mixin inline-list {
    @include reset-list;
    font-size: 0;
    > li {
        display: inline-block;
        vertical-align: top;
    }
}

@mixin bg-text-clip($background, $background-size: 100% auto, $fill-color: transparent) {
    background: $background;
    background-size: $background-size;
    -webkit-background-clip: text;
    -webkit-text-fill-color: $fill-color;
}
