//Proxima Nova
@mixin proximaNovaLight {
    font-family: "proxima-nova",sans-serif;
    font-style: normal;
    font-weight: 100;
}

@mixin proximaNova {
    font-family: "proxima-nova",sans-serif;
    font-style: normal;
    font-weight: 400;
}

//Brand Grotesque
@mixin brandGrotesque {
    font-family: "brandon-grotesque",sans-serif;
    font-style: normal;
    font-weight: 900;
}

@mixin brandGrotesqueRegular {
    font-family: "brandon-grotesque",sans-serif;
    font-style: normal;
    font-weight: 400;
}

// Letter spacing
$ls-xl: 0.1em;
$ls-lg: 0.06em;
$ls-md: 0.04em;
$ls-sm: 0.02em;
$ls-xsm: 0.01em;

// Line height
$lh-lg: 1.6;
$lh-md: 1.45;
$lh-md: 1.2;
$lh-sm: 1.1;
$lh-xsm: 0.8;

// Font size
$fs-base: 18px; // 1rem
// 70px
$fs-h1: 3.88888888888889rem;
// 40px
$fs-h2: 2.2222rem;
// 30px
$fs-h3: 1.6666rem;
// 24px
$fs-h4: 1.3333rem;
// 18px
$fs-h5: 1rem;
// 16px
$fs-h6: 0.8888rem;
// 16px
$fs-h7: 0.944rem;
