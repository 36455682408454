// Keep z-indexes for major blocks here to track overlays

$z-modal-window: 5;

$z-modal-backdrop: 4;

$z-header: 3;
$z-navbar: 2000;

$z-sticky-menu: 2;

$z: 1;

$z-bg: 0;
