//	Generic classes
.mm-hidden
{
	display: none !important;
}


//	Container
.mm-wrapper
{
	overflow-x: hidden;
	position: relative;
}


//	Menu
.mm-menu
{
	box-sizing: border-box;
	background: inherit;
	display: block;
	padding: 0;
	margin: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
}