@keyframes title-gradient {
    /*
    0%   { background-position: 14% 0; }
    50%  { background-position: 87% 100%; }
    100% { background-position: 14% 0; }
    */
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes fadeIn {
    100% {
        opacity: 0;
    }
    0% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    100% {
        opacity: 0;
    }
    0% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-animated-out {
    z-index: 1
}

.owl-animated-in {
    z-index: 0
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes imageAnimation {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1) rotate(0.00deg);
    }
    100% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        transform: scale(1.1) rotate(0.02deg);
    }
}

@-moz-keyframes imageAnimation {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1) rotate(0.00deg);
        -ms-transform: scale(1) rotate(0.00deg);
        -o-transform: scale(1);
        transform: scale(1)rotate(0.00deg);
    }
    100% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1) rotate(0.02deg);
        -ms-transform: scale(1.1) rotate(0.02deg);
        -o-transform: scale(1.1);
        transform: scale(1.1) rotate(0.02deg);
    }
}

@keyframes imageAnimation {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1) rotate(0.00deg);
        -o-transform: scale(1);
        transform: scale(1) rotate(0.00deg);
    }
    100% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1) rotate(0.02deg);
        -o-transform: scale(1.1);
        transform: scale(1.1) rotate(0.02deg);
    }
}

@keyframes fadeUpIn {
    0% {
        opacity: 0;
        top: 76%;
    }
    100% {
        opacity: 0.6;
        top: 66%;
    }
}

@keyframes fadeUpInStart {
    0% {
        opacity: 0;
        top: 78%;
    }
    100% {
        opacity: 0.6;
        top: 68%;
    }
}

@keyframes fadeUpInMisc {
    0% {
        opacity: 0;
        bottom: -60px;
    }
    100% {
        opacity: 0.6;
        bottom: 20px;
    }
}