// $border-radius: ;

// $height-navbar: ;
// $height-input: ;

$margin: 15px;

$margin-30: $margin * 2;
$margin-45: $margin * 3;
$margin-60: $margin * 4;
$margin-120: $margin * 8;

$height-input: 2rem;
