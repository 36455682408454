html {
    font-size: $fs-base;
    background-color: $black-dk;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
        -webkit-font-smoothing: subpixel-antialiased;
    }

    &.preload {
        body {
            position: fixed;
            opacity: 0;
            overflow: hidden;
            height: 100%;
            width: 100%;
        }
        @include before-absolute {
            top: 50%;
            left: 50%;
            margin: -15px 0 0 -15px;
            @include square(30px);
            -webkit-animation: rotate linear infinite 2s;
            animation: rotate linear infinite 2s;
            background-image: url(#{$images}/throbber.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
}

body {
    @include proximaNovaLight;
    color: $font-color;
    background-color: $black;
    -webkit-tap-highlight-color: transparent;
    @include transition(opacity $trans-length);
}

* {
    @include on-state {
        outline: 0;
    }
}

p {
    font-size: $fs-base;
    line-height: 32px;
    letter-spacing: $ls-xsm;
    word-spacing: -0.4px;
}

a {
    //@include proximaNovaLight;
    color: $trWhite;
    letter-spacing: 0.05em;
    //@include transition-all($trans-length);
    @include transition(color $trans-length);

    @include on-state {
        outline: 0;
        text-decoration: none;
        color: inherit;
    }
}

div {
    box-sizing: border-box;
}

img {
    display: block;
    width: 100%;
    max-width: 100%;
}

h2 {
    position: relative;
    font-size: $fs-h2;
    line-height: 1.45;
    margin: 0 0 60px;
    color: #cccccc;

    &.title.line {

        &:before {
            @include pseudo-absolute;
            bottom: -30px;
            left: 0;
            width: 60px;
            height: 3px;
            background-color: $gray-dk;
        }
    }

}

h3 {
    font-size: $fs-h3;
    color: #999999;
    line-height: $lh-md;
    position: relative;
    text-transform: uppercase;

    &.title.line {
        margin: 0 0 50px;

        &:before {
            @include pseudo-absolute;
            bottom: -24px;
            left: 0;
            width: 60px;
            height: 3px;
            background-color: $gray-dk;
        }
    }
}

h4 {
    font-size: $fs-h4;

    color: #999999;
    line-height: $lh-md;
    position: relative;
    text-transform: uppercase;

    &.title.line {
        margin: 0 0 50px;

        &:before {
            @include pseudo-absolute;
            bottom: -24px;
            left: 0;
            width: 60px;
            height: 3px;
            background-color: $gray-dk;
        }
    }
}

h5 {
    font-size: $fs-h5;
}

h6 {
    font-size: $fs-h6;
}

h1, h2, h3, h4 {
    line-height: 120%;
    @include brandGrotesque;
    a {
        color: inherit;
        @include on-state {
            color: inherit;
        }
    }
}

h5, h6 {
    line-height: 120%;
    @include brandGrotesqueRegular;
    a {
        color: inherit;
        @include on-state {
            color: inherit;
        }
    }
}

ul {
    list-style: none;
}
