body {
    .title-bg-gradient {
        display: block;
        color: rgba(255, 255, 255, 0.3);
        @include bg-text-clip(linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5)), 400% 400%);
        //animation: fadeUpIn .35s ease-in-out;

        //animation: title-gradient 10s infinite linear, fadeUpIn .35s normal ease-in-out;

        /*
        -moz-animation: title-gradient 10s ease-in-out infinite;
        -webkit-animation: title-gradient 10s ease-in-out infinite;
        animation: title-gradient 10s ease-in-out infinite;
        */
        /*
        animation-name: title-gradient, fadeUpIn;
        animation-timing-function: ease-in-out infinite;
        */
        /*animation: fadeUpIn, fadetitle-gradient;
        animation-duration: 2s, 10s;
        animation-timing-function: ease-in-out infinite;

        -webkit-animation: fadeUpIn, fadetitle-gradient;
        -webkit-animation-duration: 2s, 10s;
        -webkit-animation-timing-function: ease-in-out infinite;*/
        display: none;
    }
    &.animation {
        .title-bg-gradient {
            display: block;
            opacity: 0;
            animation-name: title-gradient, fadeUpIn;
            animation-duration: 4s, 1.6s;
            animation-timing-function: ease-out, ease-out;
            animation-iteration-count: infinite, 1;
            animation-delay: 0.5s, 0.5s;
        }
    }
    &.has-no-text-clip {
        .title-bg-gradient {
            background-image: none !important;
            background-color: transparent !important;
        }
    }
}


body {
    .page--start {
        .title-bg-gradient {
        }
    }
    &.animation {
        .page--start {
            .title-bg-gradient {
                display: block;
                animation-name: title-gradient, fadeUpInStart;
                animation-duration: 4s, 2s;
                animation-timing-function: ease-out, ease-out;
                animation-iteration-count: infinite, 1;
                animation-delay: 0.5s, 0.5s;
            }
        }
    }
}



.no-js .title-bg-gradient {
    display: block;
}


ul {
    /*list-style-image: url(../img/list-style.png);*/
    list-style: square;
    font-family: proxima-nova,sans-serif;
    font-size: 18px;
    font-style: normal;
    padding-left: 19px;
}

.page-wrapper {
    width: 100%;
    overflow: hidden;
}

.pos-r {
    position: relative;
}

.page-title {
    font-size: $fs-h1;
    margin: 0 0 $margin;
    opacity: 0;
}

.page-title {
    text-align: center;
    text-transform: uppercase;
    @include padding-h(20px);
    @include user_select(none);
    z-index: 300;
    &.pos-a {
        position: absolute;
        left: 0;
        right: 0;
    }
    &.align-middle {
        @include centered-v;
    }
    &.align-bottom {
        top: 66%;
    }
    @media ($max-xs) {
        font-size: 2.5rem;
    }
    @media ($min-sm) {
        font-size: 6.5rem;
    }
    @media ($min-lg) {
        font-size: 9rem;
    }
    @media ($min-xl) {
        font-size: 13.88888888888889rem;
    }
}

.page--start .page-title {
    //top: unset;
    //bottom: 2%;
    top: 68%;
    opacity: 0.6;
}

.full-vp {
    /*min-height: 480px;*/
    background: $gray-dk;
    @media ($min-sm) {
        height: 100vh;
        /*min-height: 780px;*/
    }
        /*
        @media ($min-lg) {
            height: 80vh;
            min-height: 780px;
        }
        */
}

.full-vp-always {
    height: 100vh;
}

.half-vp {
    background: $gray-dk;
    height: 70vh;
}

.half-opacity {
    opacity: 0.5;
    -webkit-transition: opacity 2s;
    -moz-transition: opacity 2s;
    -o-transition: opacity 2s;
    -ms-transition: opacity 2s;
    transition: opacity 2s;

    &:hover {
        opacity: 1;
    }
}

.table {
    &.table-theme {
        font-size: inherit;
        border: 2px solid;
        tbody {
            > tr {
                &:first-child {
                    > td {
                        border-top: 0;
                    }
                }
                > td {
                    padding: 14px;
                }
            }
        }
    }
}

.topics {
    h2 + p {
        margin: 0 0 77px;
        @media(max-width: 767px) {
            margin: 0 0 40px;
        }
    }
    .quote {
        background-color: $gray-xdk;
        padding: 70px 0;
        margin: 60px 0;
        font-family: "merriweather",serif;
        font-style: italic;
        font-weight: 300;
        span {
            font-style: italic;
            font-size: 28px;
            @media(max-width: 767px) {
                font-size: 18px;
            }
        }
        .title.line {
            font-size: 1.825em;
        }
        h3.title {
            /*
            font-size: 1.825em;
            margin-bottom: 8px;
            */
        }
        @media(max-width: 767px) {
            padding: 40px 0;
            margin: 0 0 40px;
        }
        @media ($min-sm) {
            margin: 115px 0;
        }
    }
}

.item-block {

    .text-wrapper {
        margin: -5px 0 0;
    }

    h2 {
        margin-top: -15px;
    }

    h3 {
        margin: 0;
    }

    p {
        line-height: 34px;
        &:not(:last-child) {
            margin: 0 0 35px;
        }
    }

    .img-wrapper {
        margin-bottom: 40px;
        @media(min-width: 992px) {
            margin-bottom: 120px;
        }
    }

    a {
        @media(min-width: 992px) {
            //margin-bottom: 115px;
        }
        //margin-bottom: 40px;
        display: inline-block;
        font-size: 18px;
        color: $blue;

        &:hover {
            color: #017dfb;
        }

        &.link {
            margin-bottom: 10px;

            &:before {
                content: "»";
                display: inline-block;
                margin-right: 4px;
            }

            &:hover {
                color: #017dfb;
                margin-left: 6px;
            }

            &:last-child {
                @media(min-width: 992px) {
                    margin-bottom: 115px;
                }
                margin-bottom: 40px;
            }
        }
    }

    .download-pdf {
        @extend .job__description;
        padding-left: 25px;
        color: white;


        &:before {
            font-family: "FontAwesome";
            content: "\f1c1";
            position: absolute;
            left: 0;
        }

    }

    .bottom-140 {
        margin-bottom: 140px;
        @media(max-width: 767px) {
            margin-bottom: 50px;
        }
    }

    .bottom-100 {
        margin-bottom: 100px;
        @media(max-width: 767px) {
            margin-bottom: 40px;
        }
    }
}

.bottom-10 {
    margin-bottom: 10px !important;
}

.bottom-30 {
    margin-bottom: 30px;
}


.bottom-40 {
    margin-bottom: 40px;
}

.bottom-100 {
    margin-bottom: $margin*2;
    @media ($min-lg) {
        margin-bottom: $margin*6;
    }
}

.rp-bottom-100 {
    @media ($max-sm){
        margin-bottom: 70px;
    }
    @media ($max-md){
        margin-bottom: 70px;
    }
    @media ($max-lg) {
        margin-bottom: 70px;
    }
}

.bottom-120 {
    margin-bottom: $margin*2;
    @media ($min-lg) {
        margin-bottom: $margin*8;
    }
}


.title {
    text-transform: uppercase;
}

.content {
    padding: 60px 0;
    @media($min-sm) {
        padding: 90px 0;
    }
    &.one-topic {
        .item-block {
            p {
                line-height: 32px;
            }
        }
    }
    &.topics {
        .item-block {
            &:last-child {
                > :last-child {
                    > :last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.left-side,
.right-side {
    > div:last-child > :last-child {
        margin-bottom: 0;
    }
}

.left-side {
    @media ($max-sm) {
        margin-bottom: 35px;
    }
}

/* JOB
-----------------------------------------------------------------*/
.job {
    margin: 0 0 20px;
    font-size: 17.5px;
}

.job:not(:last-child) {
    margin: 0 0 60px;
    font-size: 17.5px;
}

.job__block {
    margin-bottom: 20px;
}

.map-small {
    /* Set a size for our map container, the Google Map will take up 100% of this container */
    height: 580px;
    width:  100%;
    overflow: hidden;
    padding-bottom: 22.25%;
    padding-top: 30px;
    position: relative;
}

.br {
    display: block;
}

.job__description {
    font-family: "proxima-nova", "serif";
    font-style: normal;
    line-height: 32px;
}


/* INFOPOINT HOME
-----------------------------------------------------------------*/


.misc {
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
    opacity: 0.6;
    z-index: 999;
    animation-name: fadeUpInMisc;
    animation-duration: 3s;
    animation-timing-function: ease-out;
}

.infopoint {
    text-align: center;
    padding: 0;

    li {
        list-style-type: none;
        font-size: 13px;
        text-transform: uppercase;
        display: inline;

        &:nth-child(1) {
            margin: 0 20px 0 0;
        }

        a {
            color: #999;
            text-decoration: none;
            display:inline-block;

            &:hover {
                color: #fff;
                opacity: 1;
            }
        }
    }

    @media ($max-lg) {
        display: none;
    }
}