.sub-menu {
	background-color: $gray-xdk;
	text-align: center;
	//@include transition-all(0.3s);
	position: absolute;
    left: 0;
    right: 0;
    bottom: 0;


	&.scrolled {
		position: fixed;
		right: 0;
		left: 0;
		top: 69px;
		bottom: initial;
		bottom: auto;
		@media(max-width: 1200px) {
			top: 50px;
		}
		z-index: $z-sticky-menu;
        a {
            padding: 10px 0 8px;
        }
	}

	a {
		text-transform: uppercase;
		color: $gray;
		display: inline-block;
		border-bottom: 4px solid $gray-xdk;
		padding: 30px 0 22px;
		font-size: $fs-h7;
		-webkit-backface-visibility: hidden;
		font-weight: 400;

		@media(max-width: 1199px) {
			font-size: 16px;
		}
		//@include transition-all(0.3s);

		&:hover {
			border-color: $blue;
			color: white;
			opacity: 1;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		display: inline-block;
	}

	li {
		display: inline-block;
		padding: 0 10px;

		&.active {

			a {
				border-color: $blue;
				color: white;
				opacity: 1;
			}
		}
	}

    @media ($max-lg) {
        display: none;
    }
}
