//	Colors
$mm_backgroundColor: $black;
$mm_borderColor: rgba(#fff, 0.1);
$mm_dimmedTextColor: rgba(#fff, 0.3);
$mm_emphasizedBackgroundColor: rgba(#fff, 0.5);
$mm_highlightedBackgroundColor: $black;
$mm_textColor: rgba(#fff, 0.7);

// Sizes

@import "../../../../bower_components/jQuery.mmenu/src/addons/offcanvas/jquery.mmenu.offcanvas.scss";
@import "../../../../bower_components/jQuery.mmenu/src/css/jquery.mmenu.oncanvas";
@import "../../../../bower_components/jQuery.mmenu/src/extensions/positioning/jquery.mmenu.positioning.scss";

.mm-listview {
    > li {
        &:not(.mm-divider) {
            &:after {
                left: 0;
            }
        }
        > a,
        > span {
            padding: 12px 10px 12px 20px;
        }
    }

    .mm-next:before {
        display: none;
    }
    > .mm-divider {
        &:after {
            content: '';
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: $mm_borderColor;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.mm-slideout {
    cursor: pointer;
}

#mm-1 {
    .mm-navbar .mm-title {
        display: none;
    }
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), .mm-menu .mm-listview>li.mm-selected>span {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 1) !important;
    background: rgba(0,0,0,1);
}

html.mm-blocking body {
    overflow: scroll;
}

.uppercase{
    text-transform: uppercase;
}

.mm-title {
    text-transform: uppercase;
}