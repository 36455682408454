footer {
	background-color: $gray-xdk;
	text-transform: uppercase;
	padding: 56px 0;
	@media(max-width: 768px) {
		text-align: center;
	}

	ul {
		margin: 0;
		padding: 0;
		display: inline-block;
		@media(max-width: 992px) {
			margin-left: -10px;
		}
		@media (max-width: 768px) {
			margin-top: 30px;
		}
		list-style: none;
	}

	li {
		@media(min-width: 500px) {
			display: inline-block;
		}
		padding: 0 0 0 10px;

		a {
			font-size: 16px;
			display: inline-block;
			line-height: 32px;
			@include transition(0.2s);

			&:hover {
				color: white;
			}
		}
	}

	p {
		margin: 0;
		font-size: 16px;
	}
}

.rp-break {
	@media (max-width: 768px) {
		display: block;
	}
}
