@media(min-width: 1400px) {
	.container {
		width: 1250px;
	}
}

@media(min-width: 1200px) {
	.sub-menu {

		li {
			padding: 0 34px;
		}
	}

	footer {

		li {
			padding: 0 0 0 26px;
		}
	}
}

@media(min-width: 993px) {
	footer {

		ul {
			float: right;
		}
	}
}

@media(min-width: 768px) {
	.left-side {
		padding: 0 30px 0 15px;
	}

	.right-side {
		padding: 0 15px 0 30px;
	}
}

@media(max-width: 380px) {
	br {
		display: none;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 22px;
	}
}